<template>
	<div class="companyRank">
		companyRank
	</div>
</template>

<script>
	export default {
		name: 'companyRank'
	}
</script>

<style scoped>

</style>
